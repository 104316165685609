import BoxSection from "components/program/box-section"
import Brands from "components/program/brands"
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import Top from "components/program/top"
import { graphql } from "gatsby"
import svgSizeOzel from "images/kutuphane/size-ozel.svg"
import useTranslations from "components/use-translations"

export default function KutuphaneProgrami({ data }) {
  function playVideo() {
    document.getElementById("video_aciklama").style.display = "none"
    const videoBolumElement = document.getElementById("video_bolum")
    videoBolumElement.innerHTML =
      '<iframe title="Tanıtım Videosu" className="embed-responsive-item" width="560" height="315" src="https://www.youtube-nocookie.com/embed/AVbaFPtZThY?autoplay=1&rel=0&start=0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
    videoBolumElement.style.display = "block"
  }
  const ekranGoruntuleriNodes = data.ekranGoruntuleri.edges.map(({ node }) => (
    <Carousel.Item key={node.childImageSharp.fluid.src}>
      <Img fluid={node.childImageSharp.fluid} />
    </Carousel.Item>
  ))
  const {
    genel: {
      dahaFazlaBilgiMiGerekli,
      ozellikler,
      kullanimKilavuzu,
      sikSorulanSorular,
      referanslar,
      ekranGoruntuleri,
    },
    kutuphane: {
      seoTitle,
      seoDesc,
      baslik,
      altBaslik,
      altBaslikAlti,
      kullaniciMesaji,
      kullaniciAdi,
      indirButonuYazi,
      butonAltiYazi,
      referansUstuYazi,
      footerSeoMetin,
      bolumler,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDesc}
        pathname="https://www.kodvizit.com/kutuphane-programi"
        datePublished="2017-02-08T22:39:04+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container>
        <Top
          headerText={baslik}
          headerBottomText={altBaslik}
          useFreeText={altBaslikAlti}
          imgSource="/img/homepage/kutuphane-bilgisayar.png"
          quoteText={kullaniciMesaji}
          quoteAuthor={kullaniciAdi}
          buttonText={indirButonuYazi}
          buttonLink="/kutuphane-programi/indir/"
          buttonBottomText={butonAltiYazi}
          containerStyle={{ paddingBottom: 0 }}
        >
          <Img fluid={data.kutuphaneBilgisayar.childImageSharp.fluid} />
        </Top>
        <Brands brandText={referansUstuYazi}>
          <Img
            className="m-2"
            style={{ opacity: 0.5 }}
            fixed={data.referans1.childImageSharp.fixed}
          ></Img>
          <Img
            className="m-2"
            style={{ opacity: 0.5 }}
            fixed={data.referans2.childImageSharp.fixed}
          ></Img>
          <Img
            className="m-2"
            style={{ opacity: 0.5 }}
            fixed={data.referans3.childImageSharp.fixed}
          ></Img>
        </Brands>
      </Container>

      {/*  1. Şerif İle Tanışın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[0].baslik}
        descriptionText={bolumler[0].aciklama}
        listItems={bolumler[0].maddeler}
      >
        <div
          id="video_bolum"
          className="embed-responsive embed-responsive-16by9"
          style={{ display: "none" }}
        ></div>
        <div
          id="video_aciklama"
          style={{ cursor: "pointer" }}
          onClick={playVideo}
        >
          <Img fluid={data.videoAciklama.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. İşinizi hızlandırın*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[1].baslik}
        descriptionText={bolumler[1].aciklama}
        listItems={bolumler[1].maddeler}
      >
        <Img fluid={data.barkod.childImageSharp.fluid} />
      </BoxSection>

      {/* 3. Raporlayın ve inceleyin*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[2].baslik}
        descriptionText={bolumler[2].aciklama}
        listItems={bolumler[2].maddeler}
      >
        <Img fluid={data.rapor.childImageSharp.fluid} />
      </BoxSection>

      {/* 4. Size özel yazılım*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[3].baslik}
        descriptionText={bolumler[3].aciklama}
        listItems={bolumler[3].maddeler}
      >
        <img src={svgSizeOzel} alt="size özel" />
      </BoxSection>

      {/* 5. Verileriniz bizim için önemli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[4].baslik}
        descriptionText={bolumler[4].aciklama}
        listItems={bolumler[4].maddeler}
      >
        <Img fluid={data.yedekle.childImageSharp.fluid} />
      </BoxSection>

      {/* 6. Her yere uyuyor*/}
      <BoxSection childPositionLeft={true} headerText={bolumler[5].baslik}>
        <div className="text-center">
          <Img fluid={data.kurumLogoGiris.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 7. Gizlilik ve güvenlik gerekli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[6].baslik}
        descriptionText={bolumler[6].aciklama}
        listItems={bolumler[6].maddeler}
      >
        <Img fluid={data.guvenlik.childImageSharp.fluid} />
      </BoxSection>

      {/* 8. Sadelik ve basitlik*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[7].baslik}
        descriptionText={bolumler[7].aciklama}
        listItems={bolumler[7].maddeler}
      >
        <Img fluid={data.sadelik.childImageSharp.fluid} />
      </BoxSection>

      {/* 9. Kendiniz inceleyin*/}
      <BoxSection
        bgLight={true}
        childPositionLeft={true}
        headerText={bolumler[8].baslik}
        descriptionText={bolumler[8].aciklama}
      >
        <div style={{ height: "500px" }}>
          <Carousel>{ekranGoruntuleriNodes}</Carousel>
        </div>
      </BoxSection>

      {/* 10. Her zaman yanınızdayız*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[9].baslik}
        descriptionText={bolumler[9].aciklama}
        listItems={bolumler[9].maddeler}
      >
        <div style={{ maxHeight: "100%", height: "300px" }}>
          <Img
            fluid={data.destek.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "100%" }}
          />
        </div>
      </BoxSection>

      {/* 10. Kurumsalız*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[10].baslik}
        descriptionText={bolumler[10].aciklama}
        listItems={bolumler[10].maddeler}
      >
        <div style={{ maxHeight: "100%", height: "300px" }}>
          <Img
            fluid={data.kurumsal.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "100%" }}
          />
        </div>
      </BoxSection>

      <BoxSection containerStyle={{ paddingTop: 0 }}>
        <h5 className="text-center">{dahaFazlaBilgiMiGerekli}</h5>

        <ul className="list-unstyled text-center m-0" style={{ fontSize: 16 }}>
          <li className="mb-0">
            <LocalizedLink to="/kutuphane-programi/referanslar">
              {referanslar}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/kutuphane-programi/ozellikler">
              {ozellikler}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/kutuphane-programi/ekran-goruntuleri">
              {ekranGoruntuleri}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/kutuphane-programi/sik-sorulan-sorular">
              {sikSorulanSorular}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/kutuphane-programi/kullanim-kilavuzu">
              {kullanimKilavuzu}
            </LocalizedLink>
          </li>
        </ul>
        <small
          className="text-muted"
          style={{ fontSize: 10 }}
          dangerouslySetInnerHTML={{ __html: footerSeoMetin }}
        />
      </BoxSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    kutuphaneBilgisayar: file(
      relativePath: { eq: "homepage/kutuphane-bilgisayar.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    videoAciklama: file(relativePath: { eq: "kutuphane/video_aciklama.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    barkod: file(relativePath: { eq: "kutuphane/barkod.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rapor: file(relativePath: { eq: "kutuphane/rapor.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yedekle: file(relativePath: { eq: "kutuphane/yedekle.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kurumLogoGiris: file(
      relativePath: { eq: "kutuphane/kurum-logo-giris.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    guvenlik: file(relativePath: { eq: "kutuphane/guvenlik.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sadelik: file(relativePath: { eq: "kutuphane/kitap-ekle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    destek: file(relativePath: { eq: "kutuphane/destek.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kurumsal: file(relativePath: { eq: "kutuphane/aydemirler.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    referans1: file(relativePath: { eq: "homepage/referans_1.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    referans2: file(relativePath: { eq: "homepage/referans_2.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    referans3: file(relativePath: { eq: "homepage/referans_3.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ekranGoruntuleri: allFile(
      filter: {
        relativePath: { regex: "/^kutuphane/ekran-goruntuleri/sergi/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 550) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`

KutuphaneProgrami.propTypes = {
  data: PropTypes.object,
}
